import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"


const heliPidavus = () => (
  <Layout>
    <SEO title="Siirdeseinte helipidavus" />
    
    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
              <h1>SIIRDESEINAD</h1>
              <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
   </div>
   
   <div className="epoporandad" style={{
          maxWidth: 1300,
          margin: 'auto',
        }}>

        <div className="wrapper-epo">
            <Link to='/ruumijagajad/voldikuksed/'>
            <div className="epo">
                <h2>Voldikuksed</h2>
            </div>
            </Link>
            <Link to='/ruumijagajad/lootsuksed/'>
            <div className="epo">
                <h2>Lõõtsuksed</h2>
            </div>
            </Link>
            <Link to='/ruumijagajad/siirdeseinad/'
            activeStyle={{ borderBottom: '3px solid #ffa609' }}
            partiallyActive={true}>
            <div className="epo">
                <h2>Siirdeseinad</h2>
            </div>
            </Link>
            <Link to='/ruumijagajad/voldikseinad/'> 
            <div className="epo">
                <h2>Voldikseinad</h2>
            </div>
            </Link>
            <Link to='/ruumijagajad/referentsid/'>
            <div className="epo">
                <h2>Referentsid</h2>
            </div>
            </Link>
         </div>

      <div className="hp-wrapper">
        <div className="hp-text">
            <h3>Helipidavus</h3>
            <p>Ruumidesse, kuhu on seatud kõrgemad või täpsemad nõudmised helipidavuse suhtes, 
            saame pakkuda rahvusvaheliste normide nõuete järgi testitud siirdeseinasid. Seinad
            on testitud Rootsis Sveriges Provnings- och Forskningsinstitut laborites ning
            klassifitseeritud vastavalt 41 – 53 dB Rw. Arvamus helipidavuse osas 
            võib olla väga subjektiivne, mis võib osutuda teatud olukordades vägagi 
            problemaatiliseks. Järgnevas toome mõned näited, milliselt hinnatakse 
            helipidavust eri olukordades.</p>
        </div>
      </div>

  </div>


    <div className="table-grid">
        <b>Rw 52-53 dB</b>
        <p>Täielik helipidavus tavakõne suhtes. Hüüdeid, valju muusikat jms on võimalik kergelt kuulda.
        Sobiv helipidavus kõrgemate helipidavusnõuetega ruumidele.</p>

        <b>Rw 48-51 dB</b>
        <p>Helipidavus tavakõne suhtes. Hüüdeid, valjemat müra on võimalik kergelt kuulda. Sobiv helipidavus
        kõrgete helipidavusnõuetega ruumidele, kus vajatakse privaatsust, kuid täielik helipidavus
        pole vajalik.</p>

        <b>Rw 44-47 dB	</b>
        <p>Piiratud helipidavus tavakõne suhtes. Teatud osa kõvehäälelisest kõnest on võimalik kuulda,
        sõnade eristamine raskendatud. Helipidavuse tase, mis on sobilk näiteks bürooruumide osade
        eristamiseks.</p>

        <b>Rw 40-43 dB	</b>
        <p>Tavakõne on kohati eristatav, suurem müra kosta peaaegu täielikult. Sobib ruumidele, kus helipidavuse
        nõuded on madalamad.</p>

        <b>Rw 32-39 dB	</b>
        <p>Tavakõne on eristatav, suurem müra selgelt eristatav. Sobib ruumidele, kus nõuded helipidavusele on
        minimaalsed.</p>
    </div>


  </Layout>
)

export default heliPidavus